<template>
	<div class="shouye">
		<!-- <add-activity></add-activity> -->

			<!-- 
				和IndexContent两边统一
				personAndHouse 人员/房屋综合信息 /shaicha/person
				patrol 自建房巡防管理 /patrol/tasks
				ninePlace 小场所 /ninesite/sitelist
				other 数据维护 /managexiaoqu/xiaoqu
			-->
		<div class="dayuan">
			<div class="xiaoyuan"></div>
			<div class="selection">
				<div class="item item1" @click="tiaozhuan('personAndHouse', '/shaicha/person')"></div>
				<div class="item item2" @click="tiaozhuan('patrol', '/patrol/tasks')"></div>
				<div class="item item3" @click="tiaozhuan('ninePlace', '/ninesite/sitelist')"></div>
				<div class="item item4" @click="gotoPage('/monitor')"></div>
				<div class="item item5" @click="tiaozhuan('other', '/managexiaoqu/xiaoqu')"></div>
			</div>
			
		</div>
	</div>
</template>

<script>
	export default {
		name: "Index",
		data() {
			return {};
		},
		created() {},
		mounted() {

		},
		methods: {
			gotoPage(path){
				this.$router.push({
					path: path
				})
			},
			tiaozhuan(type, path) {
				window.localStorage.setItem('menuType', type);
				this.$router.push({
					path: path
				})
			}
		},
	};
</script>

<style lang="scss" scoped>
	.shouye {
		position: absolute;
		left: 0;
		top: 0;
		display: block;
		width: 100%;
		height: 100%;
		background-image: url(../assets/imgs/shouyebg.jpg);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		overflow: hidden;

		.dayuan {
			position: absolute;
			width: 3400px;
			height: 3400px;
			border-radius: 50%;
			left: 50%;
			transform: translate(-50%, 0);
			bottom: 38%;
			margin: auto;
			padding: 158px;
			box-sizing: border-box;
			// overflow: hidden;

			.xiaoyuan {
				width: 3084px;
				height: 3084px;
				border-radius: 50%;
				position: relative;
				z-index: 5;
			}

			.selection {
				width: 1050px;
				height: 247px;
				z-index: 4;
				left: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				position: absolute;
				display: flex;
				justify-content: space-between;

				.item {
					background-size: center;
					background-repeat: no-repeat;
					position: relative;
					cursor: pointer;
				}

				.item1 {
					width: 218px;
					height: 247px;
					background-image: url(../assets/imgs/indexpic/item1.png);
					background-position: center top;
				}

				.item2 {
					width: 271px;
					height: 247px;
					background-image: url(../assets/imgs/indexpic/item2.png);
					background-position: center bottom;
					left: 5px;
				}

				.item3 {
					width: 271px;
					height: 247px;
					background-image: url(../assets/imgs/indexpic/item3.png);
					background-position: center bottom;
					position: relative;
    			left: -5px;
					top: 5px;
				}

				.item4 {
					width: 218px;
					height: 247px;
					background-image: url(../assets/imgs/indexpic/item4.png);
					background-position: center bottom;
				}

				.item5 {
					width: 218px;
					height: 247px;
					background-image: url(../assets/imgs/indexpic/item5.png);
					background-position: center top;
    			left: 30px;
				}

				.item1:hover {
					background-image: url(../assets/imgs/indexpic/item1-select.png);
				}

				.item2:hover {
					background-image: url(../assets/imgs/indexpic/item2-select.png);
				}

				.item3:hover {
					background-image: url(../assets/imgs/indexpic/item3-select.png);
				}

				.item4:hover {
					background-image: url(../assets/imgs/indexpic/item4-select.png);
				}

				.item5:hover {
					background-image: url(../assets/imgs/indexpic/item5-select.png);
				}
			}

		}
	}
</style>